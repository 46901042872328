import * as React from 'react';
import { Router } from '@reach/router';
import GenericPage from '../../components/layout/CommonLayout/GenericPage';
import ClientOnly from '../../components/atoms/ClientOnly/ClientOnly';
import InitiateResetPasswordPage from '../../components/pages/reset-password/InitiateResetPasswordPage/InitiateResetPasswordPage';
import ChooseNewPasswordPage from '../../components/pages/reset-password/ChooseNewPasswordPage/ChooseNewPasswordPage';

export default function ResetPasswordPage() {
  return (
    <GenericPage>
      <ClientOnly>
        <Router basepath="/reset-password">
          <InitiateResetPasswordPage path="/" />
          <ChooseNewPasswordPage path="/:resetPasswordToken" />
        </Router>
      </ClientOnly>
    </GenericPage>
  );
}
