import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chooseNewPasswordCardContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      gap: theme.spacing(2),

      maxWidth: theme.spacing(50),
      padding: theme.spacing(2),
    },
  })
);

export default useStyles;
