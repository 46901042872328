import * as React from 'react';
import { Box } from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import SEO from '../../../atoms/SEO';
import { Button, TextField, Typography } from '../../../atoms';
import Snackbar, { SnackbarType } from '../../../molecules/Snackbar';
import useSnackbar from '../../../molecules/Snackbar/useSnackbar';
import useInitiateResetPassword from '../../../../api/accountAdministration/initiateResetPassword/useInitiateResetPassword';
import useStyles from './InitiateResetPassword.styles';

export default function InitiateResetPasswordPage(_: RouteComponentProps) {
  const classes = useStyles();

  const [email, setEmail] = React.useState('');

  const { snackbarState, displaySnackbar, hideSnackbar } = useSnackbar();

  const {
    error: initiateResetPasswordError,
    status: initiateResetPasswordStatus,
    refetch: initiateResetPassword,
  } = useInitiateResetPassword({
    email,
    queryConfig: {
      enabled: false,
      retry: false,
    },
  });

  React.useEffect(() => {
    if (initiateResetPasswordStatus === 'success') {
      displaySnackbar(
        SnackbarType.SUCCESS,
        `Reset password email sent to ${email}`
      );
    } else if (initiateResetPasswordStatus === 'error') {
      displaySnackbar(
        SnackbarType.ERROR,
        `Could not reset password: ${
          initiateResetPasswordError
            ? initiateResetPasswordError.message
            : 'unknown error'
        }`
      );
    }
  }, [initiateResetPasswordStatus]);

  return (
    <>
      <SEO title="Reset your password" description="Reset your password" />
      <Box>
        <Box className={classes.resetPasswordCardContent}>
          <Typography variant="h3" component="h1">
            Reset your password
          </Typography>

          <Typography>
            Enter your user account&#39;s verified email address and we will
            send you a password reset link.
          </Typography>

          <TextField
            id="user-email"
            label="Email"
            placeholder="john.doe@example.com"
            variant="outlined"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <Button
            variant="contained"
            color="primary"
            onClick={() => initiateResetPassword()}
          >
            Send password reset email
          </Button>
        </Box>

        <Snackbar
          show={snackbarState.show}
          type={snackbarState.type}
          hideSnackbar={hideSnackbar}
        >
          {snackbarState.content}
        </Snackbar>
      </Box>
    </>
  );
}
