import * as React from 'react';
import { Box } from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import { Button, Link, TextField, Typography } from '../../../atoms';
import SEO from '../../../atoms/SEO';
import useResetPassword from '../../../../api/accountAdministration/resetPassword/resetPassword';
import useStyles from './ChooseNewPasswordPage.styles';
import { PASSWORD_RULES } from './config';
import useSnackbar from '../../../molecules/Snackbar/useSnackbar';
import Snackbar, { SnackbarType } from '../../../molecules/Snackbar';

export default function ChooseNewPasswordPage(
  props: RouteComponentProps<{ resetPasswordToken: string }>
) {
  const classes = useStyles();

  const [password, setPassword] = React.useState('');
  const [passwordConfirmation, setPasswordConfirmation] = React.useState('');

  const { snackbarState, displaySnackbar, hideSnackbar } = useSnackbar();

  const {
    error: resetPasswordError,
    status: resetPasswordStatus,
    refetch: resetPassword,
  } = useResetPassword({
    resetPasswordToken: props.resetPasswordToken ?? '',
    newPassword: password,
    newConfirmPassword: passwordConfirmation,
    queryConfig: {
      enabled: false,
      retry: false,
    },
  });

  React.useEffect(() => {
    if (resetPasswordStatus === 'success') {
      setPassword('');
      setPasswordConfirmation('');
      displaySnackbar(
        SnackbarType.SUCCESS,
        <Typography>
          Password has been reset successfully. An email confirmation has been
          sent to your verified email address. Click{' '}
          <Link noColor textDecoration="underline" to="/">
            here
          </Link>{' '}
          to go back to the homepage.
        </Typography>
      );
    } else if (resetPasswordStatus === 'error') {
      displaySnackbar(
        SnackbarType.ERROR,
        `Could not reset password: ${
          resetPasswordError ? resetPasswordError.message : 'unknown error'
        }`
      );
    }
  }, [resetPasswordStatus]);

  return (
    <>
      <SEO title="Choose a new password" description="Choose a new password" />
      <Box>
        <Box className={classes.chooseNewPasswordCardContent}>
          <Typography variant="h3" component="h1">
            Choose a new password
          </Typography>

          <Typography>
            Please choose a new password for your account that&#39;s between{' '}
            {PASSWORD_RULES.minLength} and {PASSWORD_RULES.maxLength} characters
            long.
          </Typography>

          <TextField
            id="user-password"
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextField
            id="user-password-confirmation"
            label="Confirm password"
            type="password"
            variant="outlined"
            fullWidth
            value={passwordConfirmation}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
          />

          <Button
            variant="contained"
            color="primary"
            onClick={() => resetPassword()}
          >
            Reset password
          </Button>
        </Box>

        <Snackbar
          show={snackbarState.show}
          type={snackbarState.type}
          hideSnackbar={hideSnackbar}
        >
          {snackbarState.content}
        </Snackbar>
      </Box>
    </>
  );
}
