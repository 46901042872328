import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import API_ENDPOINTS from '../../endpoints';
import pubFetcher from '../../utils/fetcher';

export interface UseInitiateResetPasswordFetcherResponse {
  message: string;
}

export default function useInitiateResetPassword({
  email,
  queryConfig,
}: {
  email: string;
  queryConfig?: UseQueryOptions<any>;
}) {
  const url = API_ENDPOINTS.ACCOUNT_ADMINISTRATION_INITIATE_RESET_PASSWORD;

  return useQuery<UseInitiateResetPasswordFetcherResponse, Error>(
    [url, { email }],
    () =>
      pubFetcher<UseInitiateResetPasswordFetcherResponse>(url, {
        method: 'POST',
        body: JSON.stringify({ email }),
      }),
    queryConfig
  );
}
