/**
 * Gatsby has a known issue regarding rehydration / rendering. More details:
 * https://www.joshwcomeau.com/react/the-perils-of-rehydration/
 *
 * This component exists to solve this. Use it when client-side routes are used.
 */

import * as React from 'react';

export default function ClientOnly({
  children,
  ...rest
}: {
  children?: React.ReactNode;
}) {
  const [hasMounted, setHasMounted] = React.useState(false);

  React.useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return null;
  }

  return <div {...rest}>{children}</div>;
}
