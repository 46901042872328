import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import API_ENDPOINTS from '../../endpoints';
import pubFetcher from '../../utils/fetcher';

export interface UseResetPasswordFetcherResponse {
  data: string;
}

export default function useResetPassword({
  resetPasswordToken,
  newPassword,
  newConfirmPassword,
  queryConfig,
}: {
  resetPasswordToken: string;
  newPassword: string;
  newConfirmPassword: string;

  queryConfig?: UseQueryOptions<any>;
}) {
  const url =
    API_ENDPOINTS.ACCOUNT_ADMINISTRATION_RESET_PASSWORD(resetPasswordToken);

  return useQuery<UseResetPasswordFetcherResponse, Error>(
    [url, { resetPasswordToken, newPassword, newConfirmPassword }],
    () =>
      pubFetcher<UseResetPasswordFetcherResponse>(url, {
        method: 'PUT',
        body: JSON.stringify({ newPassword, newConfirmPassword }),
      }),
    queryConfig
  );
}
